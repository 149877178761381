import { createStore } from 'zustand';
import type { Content } from '../content';

export type PlaybackStore = {
  playbackSessionId?: string;
  content?: Content;
  setPlaybackSessionId: (id: string) => void;
  clearPlaybackSessionId: () => void;
  setContent: (content: Content) => void;
  clearContent: () => void;
};

export const playbackStore = createStore<PlaybackStore>()((set) => ({
  playbackSessionId: undefined,
  content: undefined,
  setPlaybackSessionId: (playbackSessionId: string) => set({ playbackSessionId }),
  clearPlaybackSessionId: () => set({ playbackSessionId: undefined }),
  setContent: (content: Content) => set({ content }),
  clearContent: () => set({ content: undefined })
}));
