import { toURL } from '../../../lib/utils';
import { z } from 'zod';

const schema = z.object({
  baseURL: z.string().url(),
  localizations: z.object({
    version: z.number(),
    supportedLocales: z.array(z.string().length(2)).min(1),
    path: z.string()
  })
});

type LocalizationConfig = z.infer<typeof schema>;

export const transformer = (localizationConfig: LocalizationConfig) => {
  const {
    baseURL,
    localizations: { path, supportedLocales }
  } = localizationConfig;

  return {
    defaultLocale: supportedLocales[0],
    supportedLocales: supportedLocales,
    localeURL: toURL(baseURL, path)
  };
};

export const localization = {
  schema,
  transformer
};
