import { getConfigFromRemote, useLocale } from '@game/core';
import { createFileRoute } from '@tanstack/react-router';
import { PurchaseSuccessful } from '../../../../app/screens/products/successful/PurchaseSuccessful';
import { upsellSuccessfulSchema } from '../../../../lib/config';
import { purchaseSuccessful } from '../../../../lib/locale';

export const Route = createFileRoute('/_private/products_/successful')({
  component: RouteComponent
});

function RouteComponent() {
  const { locales } = useLocale({ schema: purchaseSuccessful });
  const {
    upsell: { delayMinutes }
  } = getConfigFromRemote(upsellSuccessfulSchema);

  return <PurchaseSuccessful locales={locales} delayMinutes={delayMinutes} />;
}
