import dayjs from 'dayjs';
import { z } from 'zod';
import { GameConfig } from '../../config/game-config';
import type { Platform } from '../../lib/platform';
import { queryClient } from '../../lib/query';
import { isKeyOfObject, toURL } from '../../lib/utils';
import { numberToOrdinal } from '../../lib/utils/numberFormat';
import { getAiring } from '../../services/api/quickplay';
import type {
  Entitlement,
  QuickplayAsset,
  QuickplayCatalogType,
  QuickplayContainerData,
  QuickplayContent,
  QuickplayPaginationHeader,
  QuickplayTeam
} from '../../services/api/quickplay/types';
import { getConfigFromRemote } from '../config-manager';
import type { Score } from '../scores/models';
import { getMultipleScores } from '../scores/services';
import { getRSNsSetFromActiveSubscriptions } from '../subscription-plans/utils';
import { getPrimaryTeam, getUserFavouriteTeamsMapQuery } from '../teams';
import { GetUserQuery, type User } from '../user';
import { toContent } from './models';
import { GetAiringsQuery } from './query';
import type { CardStyle, Content, ContentStatus, ContentType, GameDetails, Rail } from './type';
import { getAllRSNs } from '../rsns/service';

type LeaguePeriodTitle = {
  short: string;
  title: string;
};

const ONE_HOUR = 60 * 60 * 1000;

const LEAGUE_TO_PERIOD: Record<string, LeaguePeriodTitle> = {
  mlb: {
    short: 'INN',
    title: 'Inning'
  },
  nhl: {
    short: 'PER',
    title: 'Period'
  },
  nba: {
    short: 'QTR',
    title: 'Quarter'
  }
} as const;

export const TYPE_MAP = {
  episode: 'vod',
  channel: 'linear',
  liveevent: 'overflow',
  show: 'vod',
  airing: 'linear'
} satisfies Record<string, ContentType>;

export function qpImageResizerUrl() {
  const config = getConfigFromRemote(
    z.object({
      imageResizer: z.object({
        url: z.string()
      })
    })
  );

  return config.imageResizer.url;
}

export function getCardStyle(container: QuickplayContainerData): CardStyle {
  const { s, stl, iar } = container;

  if (iar && (iar.includes('3x4') || iar.includes('2x3'))) return 'poster';

  if (stl && stl === 'rounded') return 'rounded';

  if (s === 'small' || s === 'regular') {
    return 'card-s';
  }

  return 'card-m';
}

export function hasNextPage(header: QuickplayPaginationHeader) {
  return header.rows + header.start - 1 < header.count;
}

export function getContentTypeId(content: QuickplayContent): 'vod' | 'live' {
  return content?.cty === 'episode' ? 'vod' : 'live';
}

export function getPlaybackCatalogType(content: QuickplayContent) {
  if (content.cty === 'airing') return 'channel';

  if (content.cty === 'show') return 'episode';

  return content.cty;
}

export function getPlaybackProps(content: QuickplayContent) {
  const contentTypeId = getContentTypeId(content);

  const playbackCatalogType = getPlaybackCatalogType(content);

  const playbackId = playbackCatalogType === 'channel' ? (content.cid ?? content.id) : content.id;

  return {
    contentTypeId,
    playbackCatalogType,
    playbackId
  };
}

export function getTitle(content: QuickplayContent) {
  return content?.lon?.[0]?.n ?? content?.pgm?.lon?.[0]?.n ?? '';
}

type ContentFormatType = QuickplayCatalogType | 'default';

type ContentFormat = {
  startDate?: string; // date format
  duration?: string; // duration format - content under an hour
  durationOverHour?: string; // duration format - content over or equal to an hour
  descriptionParts: {
    startDate: boolean;
    duration: boolean;
    providerName: boolean;
  };
};
const contentFormatMap = new Map<ContentFormatType, ContentFormat>();

contentFormatMap.set('default', {
  startDate: 'MMM D, YYYY',
  duration: 'm:ss',
  durationOverHour: 'H:mm:ss',
  descriptionParts: {
    startDate: true,
    duration: true,
    providerName: true
  }
});

contentFormatMap.set('liveevent', {
  startDate: 'MMM D, YYYY h:mma',
  descriptionParts: {
    startDate: true,
    duration: true,
    providerName: true
  }
});

contentFormatMap.set('airing', {
  startDate: 'MMM D, YYYY h:mma',
  descriptionParts: {
    startDate: true,
    duration: true,
    providerName: true
  }
});

function getStartDateFormatted(content: QuickplayContent) {
  const { startDate } = getContentDates(content);
  if (!startDate) return;
  const formatStr = contentFormatMap.get(content.cty)?.startDate || contentFormatMap.get('default')?.startDate;
  return dayjs(startDate).format(formatStr);
}

function getDurationFormatted(content: QuickplayContent) {
  const { vrt } = content;
  if (vrt === undefined) return;
  const durationFmt = vrt >= ONE_HOUR ? 'durationOverHour' : 'duration';
  const formatStr = contentFormatMap.get(content.cty)?.[durationFmt] || contentFormatMap.get('default')?.[durationFmt];
  return dayjs.duration(vrt).format(formatStr);
}

export function getDescription(content: QuickplayContent) {
  const partsConfig =
    contentFormatMap.get(content.cty)?.descriptionParts ?? contentFormatMap.get('default')?.descriptionParts;
  if (!partsConfig) return;
  const parts: Array<string> = [];
  if (partsConfig.startDate) {
    const startDateFormatted = getStartDateFormatted(content);
    if (startDateFormatted) parts.push(startDateFormatted);
  }
  if (partsConfig.duration) {
    const duration = getDurationFormatted(content);
    if (duration) parts.push(duration);
  }
  if (partsConfig.providerName) {
    const { pn } = content;
    const providerName = pn?.toUpperCase();
    if (providerName) parts.push(providerName);
  }
  return parts.join(' | ');
}

export function getContentDates(content: QuickplayContent) {
  const startDate = content?.ev_st_dt ?? content?.sc_st_dt;
  const endDate = content?.ev_ed_dt ?? content?.sc_ed_dt;

  return {
    startDate: startDate ? dayjs(startDate).toDate() : undefined,
    endDate: endDate ? dayjs(endDate).toDate() : undefined
  };
}

export function getExternalImageAssets(assets?: QuickplayAsset[], options?: ImageAssetOptions) {
  if (!assets?.length) return;

  const preferredAspectRatio = options?.preferredAspectRatios?.platformAR ?? options?.preferredAspectRatios?.defaultAR;
  for (const aspectRatio of EXTERNAL_ASPECT_RATIOS) {
    const imageData = assets.find((asset) => asset.ar === (preferredAspectRatio ?? aspectRatio));

    if (imageData) {
      return toURL(imageData.u);
    }
  }

  return toURL(assets[0].u);
}

const WEB_IMAGE_SIZES = {
  poster: { height: '480' },
  'card-s': { width: '480' },
  'card-m': { width: '610' },
  rounded: { height: '210' }
};

const CTV_IMAGE_SIZES = {
  poster: { height: '430' },
  'card-s': { width: '450' },
  'card-m': { width: '670' },
  rounded: { height: '300' }
};

const IMAGE_SIZE_PLATFORM_CARDSTYLE = {
  'web:mobile': WEB_IMAGE_SIZES,
  web: WEB_IMAGE_SIZES,
  androidtv: CTV_IMAGE_SIZES,
  appletv: CTV_IMAGE_SIZES,
  firetv: CTV_IMAGE_SIZES,
  lg: CTV_IMAGE_SIZES,
  roku: CTV_IMAGE_SIZES,
  samsung: CTV_IMAGE_SIZES,
  vizio: CTV_IMAGE_SIZES
} satisfies Record<Platform, Record<CardStyle, ImageAssetSize>>;

export function getImageSizeByCardStyle(cardStyle: CardStyle) {
  const platform = GameConfig.get.platform;

  return IMAGE_SIZE_PLATFORM_CARDSTYLE[platform][cardStyle];
}

const EXTERNAL_ASPECT_RATIOS = ['16:9', '2:1', '4:3', '3:2'] as const;

const HORIZONTAL_ASPECT_RATIOS = ['16x9', '16x6', '2x1', '4x3', '3x2'] as const;

const VERTICAL_ASPECT_RATIOS = ['4x5', '3x4', '2x3'] as const;

type ImageAssetSize = { width?: string; height?: string };

type GenerateImageAssetUrlParams = {
  id: string;
  img: string;
  extension: string;
  size: ImageAssetSize;
};

export function generateImageAssetUrl(params: GenerateImageAssetUrlParams) {
  const { id, img, extension, size } = params;

  return toURL(qpImageResizerUrl(), `image/${id}/${img}.${extension}`, size);
}

type ImageAssetOptions = {
  size: ImageAssetSize;
  extension?: string;
  preferredAspectRatios?: { defaultAR?: string; platformAR?: string; cardStyle?: CardStyle };
};

export function getAssetByOrderOfPriority(imageAssets: string[], cardStyle?: CardStyle) {
  const priorityAssets = cardStyle === 'poster' ? VERTICAL_ASPECT_RATIOS : HORIZONTAL_ASPECT_RATIOS;

  for (const asset of priorityAssets) {
    for (const ia of imageAssets) {
      if (ia.includes(asset)) return ia;
    }
  }

  return imageAssets[0];
}

export function getImageAssetUrl(content: { id: string; ia?: string[] }, options: ImageAssetOptions) {
  if (!content.ia) return undefined;

  const { ia: imageAssets, id } = content;

  const { size, preferredAspectRatios, extension = 'jpg' } = options;

  if (preferredAspectRatios?.platformAR && imageAssets.includes(preferredAspectRatios.platformAR))
    return generateImageAssetUrl({ id, size, extension, img: preferredAspectRatios.platformAR });

  if (preferredAspectRatios?.defaultAR && imageAssets.includes(preferredAspectRatios.defaultAR))
    return generateImageAssetUrl({ id, size, extension, img: preferredAspectRatios.defaultAR });

  return generateImageAssetUrl({
    id,
    size,
    extension,
    img: getAssetByOrderOfPriority(imageAssets, preferredAspectRatios?.cardStyle)
  });
}

export function getImageUrl(content: QuickplayContent, options: ImageAssetOptions) {
  const externalImage = content.pgm?.ex_ia;

  if (externalImage) {
    return getExternalImageAssets(externalImage, options);
  }

  return getImageAssetUrl(content, options);
}

export function getContainerImageAspectRatio(container: QuickplayContainerData, defaultAspectRatio?: boolean) {
  if (!container.diar) return undefined;

  const defaultAR = container.diar.find((item) => item.dt === 'default')?.iar;

  if (defaultAspectRatio) return defaultAR;

  const dt = GameConfig.get.basePlatform === 'web' ? 'browser' : 'tv';
  return container.diar.find((item) => item.dt === dt)?.iar ?? defaultAR;
}

type getSpecificAspectRatioImageParams = {
  content: QuickplayContent;
  aspectRatio: string;
  size: ImageAssetSize;
  extension?: string;
};

export function getSpecificAspectRatioImage(params: getSpecificAspectRatioImageParams) {
  const { content, aspectRatio, size, extension = 'jpg' } = params;

  if (!content.ia || !content.ia.includes(aspectRatio)) return undefined;

  return generateImageAssetUrl({ id: content.id, img: aspectRatio, size, extension });
}

export function getContentStatus(stDt?: Date, edDt?: Date): ContentStatus {
  if (!stDt || !edDt) return 'POST';

  const now = dayjs();
  const startDate = dayjs(stDt);

  if (now.isBefore(startDate)) return 'PRE';

  const endDate = dayjs(edDt);

  if (now.isBefore(endDate)) return 'LIVE';

  return 'POST';
}

export function getIsLIve(content: QuickplayContent) {
  if (content?.ev_live) return content.ev_live === 'true';

  return content.cty === 'liveevent';
}

export function getContentGameDetails(content: QuickplayContent) {
  const qpTeam = content?.pgm?.tm ?? content?.tm;

  if (!qpTeam?.length) return;
  const { spt_lg, pgm } = content;

  const league = (spt_lg ?? pgm?.spt_lg ?? '').split(' ')[0].toLowerCase();
  const externalId = content.ex_id?.split('-')[0];

  const gameDetails: GameDetails = {
    league,
    currentPeriod: '-',
    periodTitle: LEAGUE_TO_PERIOD[league]?.title ?? '',
    periodTitleShort: LEAGUE_TO_PERIOD[league]?.short ?? '',
    periodHalf: '-',
    externalId
  };

  gameDetails.teams = qpTeam.map((team) => ({
    type: team?.ty,
    logoURL: getTeamLogoURL(team),
    shortName: team?.losn?.[0].n ?? team?.lon[0].n, // fallback to long name if short name is not available
    score: '',
    tricode: getTeamTricode(team)
  }));

  return gameDetails;
}

function getTeamLogoURL(team: QuickplayTeam) {
  const url = getExternalImageAssets(team?.ex_ia);
  if (url) return url;
  if (!team.ia?.length || !team.c) return;
  const asset = { id: team.c, ia: team.ia.slice(-1) };
  const options = { size: { width: '100' }, extension: 'png' };
  return getImageAssetUrl(asset, options);
}

export function getTeamsKeys(content: QuickplayContent) {
  const keys: string[] = [];

  if (content?.aw_tm) keys.push(content.aw_tm);
  if (content?.hm_tm) keys.push(content.hm_tm);

  const teams = content?.pgm?.tm ?? content?.tm;

  if (!teams?.length) return keys;

  // @ts-expect-error filtering out team.c === undefined, but ts doesn't recognize that
  keys.push(...teams.filter((team) => team.c).map((team) => team.c));

  return keys;
}

export function getGamePassAssetId(content: QuickplayContent) {
  if (content.pt === 'TVOD') return content.ex_id;
}

export function getQPEntitlements(entitlements?: Entitlement[]) {
  const qpEntitlements = new Set<string>();

  if (entitlements) {
    for (const ent of entitlements) {
      for (const item of ent.sp) {
        qpEntitlements.add(item);
      }
    }
  }

  return qpEntitlements;
}

export function getContentEntitlements(content: QuickplayContent) {
  const contentEntitlements = getQPEntitlements(content.ent);

  const gamePassId = getGamePassAssetId(content);

  if (gamePassId) {
    contentEntitlements.add(gamePassId);
  }

  return contentEntitlements;
}

export function getTeams(gameDetails?: GameDetails) {
  if (!gameDetails) return {};
  const teams = gameDetails.teams;
  if (!teams) return {};

  return {
    homeTeam: teams.find((team) => team.type === 'home'),
    awayTeam: teams.find((team) => team.type === 'away')
  };
}

// -------- LIB UTILS ----------
function isGameWithScore(content: Content) {
  return content.isGame && content.isLive && content.channelId && content.status !== 'PRE';
}

function getStationsFromContent(content: Content) {
  const stationsMap = getTeamSportStation();

  return (
    content.gameDetails?.teams
      ?.map((team) => {
        const station = stationsMap.get(team.tricode ?? '');

        return station ?? null;
      })
      .filter((station) => station != null) ?? null
  );
}

export async function updateContentWithScores(contentList: Content[]) {
  const stationsToFetch = contentList.reduce((res, content) => {
    if (!isGameWithScore(content)) return res;

    const stations = getStationsFromContent(content);

    if (stations) {
      stations.forEach((station) => res.add(station));
    }

    return res;
  }, new Set() as Set<string>);

  if (!stationsToFetch.size) return contentList;

  const allStationsScores = await getMultipleScores(Array.from(stationsToFetch));

  if (!allStationsScores) return contentList;

  return contentList.map((content) => {
    if (!isGameWithScore(content) || !content.gameDetails) return content;

    const stations = getStationsFromContent(content);

    if (!stations) return content;

    let scores: Score | null = null;

    for (const station of stations) {
      scores = allStationsScores.get(station) ?? null;

      if (scores != null) break;
    }

    if (!scores) return content;

    const { period, currentPeriodSegment, teams } = scores;

    return {
      ...content,
      gameDetails: {
        ...content.gameDetails,
        currentPeriod: numberToOrdinal(period ?? content.gameDetails.currentPeriod),
        periodHalf: currentPeriodSegment ?? content.gameDetails.periodHalf,
        teams: content.gameDetails.teams?.map((team) => {
          const scoresTeam = teams.get(team?.type);

          return {
            ...team,
            shortName: scoresTeam?.shortName ?? team?.shortName,
            score: scoresTeam?.score?.toString() ?? team?.score
          };
        })
      }
    };
  });
}

export async function getRSNContentFilter() {
  const rsnSet = await getRSNsSetFromActiveSubscriptions();
  return rsnSet ? Array.from(rsnSet).sort() : null;
}

function heroContentSort(a: Content, b: Content) {
  // These zeroes are here just in case of possible failure and typescript complaining,
  // but it shouldn't cause errors, since the items were checked before running here
  const rankA = a.gameDetails?.teams ? (getPrimaryTeam(a.gameDetails.teams)?.rank ?? 0) : 0;
  const rankB = b.gameDetails?.teams ? (getPrimaryTeam(b.gameDetails.teams)?.rank ?? 0) : 0;

  // 1) If both had already begun, then priority is team rank:
  if (a.status !== 'PRE' && b.status !== 'PRE') {
    return rankA - rankB;
  }

  // 2) If one game is live and the other is not, the live one goes first
  if (a.status !== 'PRE' && b.status === 'PRE') {
    return -1;
  }

  if (a.status === 'PRE' && b.status !== 'PRE') {
    return 1;
  }

  // 3) If both are not live but they have the same start time, order by rank
  const startDateA = dayjs(a.startDate);
  const startDateB = dayjs(b.startDate);

  if (startDateA.isSame(startDateB)) {
    return rankA - rankB;
  }

  // 4) Last check, sort by start time in chronological order
  return startDateA.isBefore(startDateB) ? -1 : 1;
}

export async function getContentSortedByFavorites(contentList: Content[] | null) {
  if (contentList == null) return contentList;

  const userFavoriteTeamsMap = await queryClient.fetchQuery(getUserFavouriteTeamsMapQuery());

  if (!userFavoriteTeamsMap.size) return contentList;

  const userFavoriteTeamsKeys = Array.from(userFavoriteTeamsMap).flatMap((team) => [team[1].code, team[1].externalId]);

  const contentsWithFavorites: Content[] = [];
  const contentsWithoutFavorites: Content[] = [];

  for (const content of contentList) {
    if (content.isGame && content.teamsKeys.some((key) => userFavoriteTeamsKeys.includes(key))) {
      contentsWithFavorites.push(content);
    } else {
      contentsWithoutFavorites.push(content);
    }
  }

  if (!contentsWithFavorites.length) return contentList;

  contentsWithFavorites.sort(heroContentSort);

  for (const content of contentsWithoutFavorites) {
    contentsWithFavorites.push(content);
  }

  return contentsWithFavorites;
}

const teamsConfigSchema = z.object({
  teams: z.array(
    z.object({
      sportsRadarStationID: z.string(),
      tri: z.string(),
      cmsInfo: z.object({
        exID: z.string(),
        teamCode: z.string()
      })
    })
  )
});

export async function updateChannelContent(region: string, contentList: Content[], user: User) {
  const airings = await queryClient.fetchQuery(GetAiringsQuery({ region, user }));

  return contentList.map((content) => {
    if (content.catalogType !== 'channel') return content;

    const foundAiring = airings.find((airing) => airing?.channelId === content.id);

    if (!foundAiring) return content;

    for (const key in foundAiring) {
      if (isKeyOfObject(key, foundAiring) && foundAiring[key])
        // @ts-expect-error ts complaining
        content[key] = foundAiring[key];
    }

    return content;
  });
}

export function getTeamSportStation() {
  const config = getConfigFromRemote(teamsConfigSchema);

  return new Map(
    config.teams.flatMap((team) => [
      [team.cmsInfo.teamCode, team.sportsRadarStationID],
      [team.cmsInfo.exID, team.sportsRadarStationID],
      [team.tri, team.sportsRadarStationID]
    ])
  );
}

function isRailType(rail: Rail, type: 'promotional' | string) {
  return rail.type === type;
}

function comparePromotional(a: Rail, b: Rail) {
  if (a.type === b.type) return 0;
  if (isRailType(a, 'promotional')) return 1;
  if (isRailType(b, 'promotional')) return -1;
  return 0;
}

function compareUserFavorites(this: any, a: Rail, b: Rail) {
  const isAFav = this.has(a.type) ? 1 : 0;
  const isBFav = this.has(b.type) ? 1 : 0;

  return -(isAFav - isBFav);
}

export async function getSortedRails(containers: Rail[]) {
  const userFavoriteTeamsMap = await queryClient.fetchQuery(getUserFavouriteTeamsMapQuery());

  return containers.sort(compareUserFavorites.bind(userFavoriteTeamsMap)).sort(comparePromotional);
}

export function getTeamTricode(team: QuickplayTeam): string | undefined {
  if (!team.c) return;
  return team.c.includes('_') ? team.c.split('_')[1] : team.c;
}

// -------------------------- DESCRIPTION UTILITIES ------------------------------

export function getScheduleGamesDescription(content: Content) {
  const parts = [];
  const gameDate = dayjs(content.startDate).format('MMM D | h:mm a');
  parts.push(gameDate);

  const net = content?.network ?? content?.providerName;
  if (net) {
    parts.push(net.toUpperCase());
  }
  return parts.join(' | ');
}

export function getScheduleGamesTitle(content: Content) {
  const { awayTeam, homeTeam } = getTeams(content.gameDetails);
  return awayTeam && homeTeam ? `${awayTeam.tricode} vs ${homeTeam.tricode}` : content.title;
}

export async function getChannelContent(content: Content): Promise<Content | null> {
  const user = queryClient.getQueryData(GetUserQuery.queryKey);
  if (user && content.catalogType === 'channel') {
    const airingItems = await getAiring(user?.entitlementsZone ?? '');
    const airingItem = airingItems?.find((a) => a.cid === content.id);

    if (airingItem) {
      return toContent(airingItem, user as User);
    }
  }
  return null;
}

export function getContentRSN(content: Content) {
  const rsns = getAllRSNs();
  return rsns.find((r) => r.name.toLowerCase().includes((content.providerName || content.network) ?? '')) || rsns[0];
}
