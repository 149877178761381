import { isSafari } from '../user-agent/user-agent';
import { getConfigFromRemote } from '@game/core';
import { watchConfigSchema } from '../config';

type StreamFormat = 'hls' | 'dash';

export function getStreamFormat(): StreamFormat {
  const { safariMediaFormat, defaultMediaFormat } = getConfigFromRemote(watchConfigSchema);
  return (isSafari() ? safariMediaFormat : defaultMediaFormat) as StreamFormat;
}
