import { useScript } from 'usehooks-ts';
import { GOOGLE } from '../../config/build-config';

export function usePAL() {
  const status = useScript(GOOGLE.PAL.SDK_URL);

  return {
    status
  };
}
