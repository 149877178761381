import { emit, getConfigFromRemote } from '@game/core';
import { createFileRoute } from '@tanstack/react-router';
import { launch as launchConfig } from '../../../../lib/config';
import { redirectSearchSchema } from '../../../../lib/routing/redirect-search';

export const Route = createFileRoute('/_public/_unauthenticated/launch')({
  validateSearch: redirectSearchSchema,
  beforeLoad: ({ context: { queryClient }, search }) => {
    queryClient.clear();
    emit('NavigateLaunch');
    return {
      footer: false, // Launch has custom implementation as it is part of the login component.
      config: getConfigFromRemote(launchConfig),
      redirect: search
    };
  }
});
