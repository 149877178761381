import { queryClient, QueryClientProvider, usePAL } from '@game/core';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { useEffect, useRef } from 'react';
import { AppHelmet } from './components/helmet/AppHelmet';
import { Spinner } from './components/spinner/Spinner';
import { initializeBraze } from './lib/braze/lib';
import { routeTree } from './routeTree.gen';

const router = createRouter({
  routeTree,
  context: { queryClient },
  defaultPendingComponent: Spinner,
  defaultPendingMs: 1000,
  defaultPreloadStaleTime: 0
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export function App() {
  const brazeLoadedRef = useRef(false);

  const { status } = usePAL();

  useEffect(() => {
    if (brazeLoadedRef.current) return;
    brazeLoadedRef.current = initializeBraze();
  }, []);

  useEffect(() => {
    const preloadErrorHandler = (event: VitePreloadErrorEvent) => {
      event.preventDefault();
      void queryClient.invalidateQueries();
      window.location.reload();
    };

    window.addEventListener('vite:preloadError', preloadErrorHandler);

    return () => {
      window.removeEventListener('vite:preloadError', preloadErrorHandler);
    };
  }, []);

  return status === 'error' || status === 'ready' ? (
    <>
      <AppHelmet />
      <QueryClientProvider>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </>
  ) : null;
}
