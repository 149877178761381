import { createFileRoute, redirect } from '@tanstack/react-router';
import { getPagePath } from '../../lib/routing';

export const Route = createFileRoute('/(deeplink)/page/$')({
  beforeLoad: ({ params, search }) => {
    const { _splat } = params;

    if (_splat === 'upsell') {
      throw redirect({ to: getPagePath('products') });
    }

    throw redirect({ to: `/${_splat}`, search });
  }
});
