import { getAllRSNs } from './service';
import type { RSN } from './type';

type OrderBy = keyof RSN['displayOrder'];

export function sortByRSN(order?: OrderBy, a?: RSN, b?: RSN) {
  if (a && b) {
    return a.displayOrder[order ?? 'default'] - b.displayOrder[order ?? 'default'];
  }
  return a ? -1 : 1;
}

export function getRSNs(order?: OrderBy) {
  return getAllRSNs().sort(sortByRSN.bind(null, order));
}

export function getRSNMap(key?: 'id' | 'name') {
  return getRSNs().reduce((map, rsn) => {
    const keyValue = rsn[key ?? 'id'];
    map.set(keyValue, rsn);
    return map;
  }, new Map<string | number, RSN>());
}

export function findRSN(rsnId: number | null) {
  return rsnId ? (getRSNs().find((rsn: RSN) => rsn.id === rsnId) ?? null) : null;
}
