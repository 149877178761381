import { createFileRoute, redirect } from '@tanstack/react-router';
import { getPagePath } from '../../../../../lib/routing';
import { isFeatureEnabled } from '@game/core';

export const Route = createFileRoute('/_private/_home/account/profile')({
  beforeLoad: async () => {
    const account = await isFeatureEnabled('account');
    if (account?.manageProfile === false) {
      throw redirect({ to: getPagePath('account') });
    }
  }
});
