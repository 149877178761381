import { UAParser } from 'ua-parser-js';
import { getDeviceId } from '../device/device';
import {
  GameConfig,
  generateUUID,
  getAssetPlaybackData,
  getContentRSN,
  getUserData,
  getUserLocation,
  type Content
} from '@game/core';
import { getStreamFormat } from '../watch/lib';
import { getPagePath } from '../routing';

export async function getDeviceDetails() {
  const userAgent = UAParser();

  return {
    deviceName: userAgent.browser?.name ?? 'browser',
    deviceType: 'browser',
    brand: '',
    serialNo: await getDeviceId(),
    modelNo: '',
    name: '',
    appType: '',
    locale: '',
    type: ''
  };
}

// TODO: merge this service with signed_playback_url query function in play-video loader
export async function fetchPreviewURL(content: Content) {
  const rsn = getContentRSN(content);
  const { zipCode, mvpdId: mvpd } = await getUserData();
  const device = {
    id: await getDeviceId(),
    type: 'web', // TODO: TBD.
    limitAdTracking: false,
    platform: GameConfig.get.basePlatform,
    sessionKey: generateUUID()
  };

  const response = await getAssetPlaybackData({
    video: {
      id: content.playbackId,
      type: content.contentTypeId,
      catalogType: content.playbackCatalogType,
      title: content.title,
      gamePassAssetId: content.gamePassAssetId
    },
    streamType: getStreamFormat(),
    resourceId: rsn.adobeResourceId,
    ads: {
      videoPath: getPagePath('playback'),
      zipCode: zipCode ?? (await getUserLocation()).zip,
      // nonce,
      device
    },
    mvpd
  });

  const {
    data: { contentUrl: url, mediaFormat: streamType }
  } = response;

  return { url, streamType };
}
