import { isFeatureEnabled } from '@game/core';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_private/_home/account/support')({
  loader: async () => {
    const support = await isFeatureEnabled('support');
    return {
      showPhoneButton: support ? support.phone : true
    };
  }
});
