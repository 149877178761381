import { z } from 'zod';
import { toURL } from '../../lib/utils';

const platformSchema = z.object({
  commonAssetsPath: z.string()
});

export const rsnConfigSchema = z
  .object({
    baseURL: z.string(),
    rsns: z.array(
      z.object({
        id: z.number(),
        name: z.string(),
        priority: z.number(),
        priorityOnSchedule: z.number(),
        logoLarge: z.string(),
        defaultHeroBG: z.string(),
        csaiEnabled: z.boolean(),
        csaiAdProvider: z.string(),
        adobeResourceId: z.string()
      })
    ),
    assets: z.object({
      companyLogos: z.object({
        logos: z.array(
          z.object({
            rsnID: z.number(),
            full: z.object({
              url: z.string()
            })
          })
        )
      })
    }),
    web: platformSchema,
    lg: platformSchema,
    vizio: platformSchema,
    samsung: platformSchema
  })
  .transform((config) => {
    const {
      baseURL,
      rsns,
      web,
      lg,
      vizio,
      samsung,
      assets: {
        companyLogos: { logos }
      }
    } = config;
    return rsns.map((rsn) => {
      const rsnLogo = logos.find((logo) => logo.rsnID === rsn.id);
      const { priority, priorityOnSchedule, logoLarge, defaultHeroBG, ...rest } = rsn;

      return {
        displayOrder: {
          default: priority,
          schedule: priorityOnSchedule
        },
        logo: toURL(baseURL, rsnLogo?.full.url),
        web: {
          logoLarge: toURL(baseURL, `${web.commonAssetsPath}${logoLarge}`),
          defaultHeroBg: toURL(baseURL, `${web.commonAssetsPath}${defaultHeroBG}`)
        },
        lg: {
          logoLarge: toURL(baseURL, `${lg.commonAssetsPath}${logoLarge}`),
          defaultHeroBg: toURL(baseURL, `${lg.commonAssetsPath}${defaultHeroBG}`)
        },
        vizio: {
          logoLarge: toURL(baseURL, `${vizio.commonAssetsPath}${logoLarge}`),
          defaultHeroBg: toURL(baseURL, `${vizio.commonAssetsPath}${defaultHeroBG}`)
        },
        samsung: {
          logoLarge: toURL(baseURL, `${samsung.commonAssetsPath}${logoLarge}`),
          defaultHeroBg: toURL(baseURL, `${samsung.commonAssetsPath}${defaultHeroBG}`)
        },
        ...rest
      };
    });
  });

export type RSNConfigType = z.infer<typeof rsnConfigSchema>[0];
