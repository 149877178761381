import type { IconConfigSchema } from './types';

export const DEFAULT_HEIGHT = 20;

export const DEFAULT_WIDTH = 26;

export const DEFAULT_VIEWBOX = `0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`;

/**
 * This configuration object should be updated every time an icon is added.
 * It's important to check the viewbox attr from the svg you are adding,
 * to keep the image consistent and not overflowing, thus cutting the image.
 * If the icon you're adding follows the same viewbox as DEFAULT_VIEWBOX
 * then you can just use this constant to populate.
 */
export const ICON_CONFIG = {
  'eyes-closed': {
    viewbox: DEFAULT_VIEWBOX,
    baseClassName: 'fill-primary-500'
  },
  'eyes-open': {
    viewbox: DEFAULT_VIEWBOX,
    baseClassName: 'fill-primary-500'
  },
  'chevron-down': {
    viewbox: '0 0 26 14',
    baseClassName: 'stroke-primary-500 fill-none'
  },
  check: {
    viewbox: '0 0 38 36',
    baseClassName: 'stroke-primary-500'
  },
  'circle-check': {
    viewbox: '0 0 210 210',
    baseClassName: 'stroke-primary-500'
  },
  close: {
    viewbox: '0 0 44 43',
    baseClassName: 'fill-secondary-50'
  },
  'chevron-right': {
    viewbox: '0 0 22 38',
    baseClassName: 'stroke-secondary-50 fill-transparent'
  },
  'chevron-left': {
    viewbox: '0 0 22 38',
    baseClassName: 'stroke-secondary-50'
  },
  apple: {
    viewbox: '0 0 31 38',
    baseClassName: ''
  },
  google: {
    viewbox: '0 0 41 41',
    baseClassName: ''
  },
  facebook: {
    viewbox: '0 0 40 40',
    baseClassName: ''
  },
  'subscription-star': {
    viewbox: '0 0 71 65',
    baseClassName: ''
  },
  search: {
    viewbox: '0 0 32 32',
    baseClassName: 'fill-secondary-50'
  },
  watch: {
    viewbox: '0 0 32 32',
    baseClassName: 'fill-secondary-50'
  },
  schedule: {
    viewbox: '0 0 32 32',
    baseClassName: 'fill-secondary-50'
  },
  odds: {
    viewbox: '0 0 32 32',
    baseClassName: 'fill-secondary-50'
  },
  account: {
    viewbox: '0 0 32 32',
    baseClassName: 'fill-secondary-50'
  },
  menu: {
    viewbox: '0 0 30 24',
    baseClassName: 'fill-secondary-50'
  },
  lock: {
    viewbox: '0 0 40 40',
    baseClassName: ''
  },
  add: {
    viewbox: '0 0 40 40',
    baseClassName: ''
  },
  minus: {
    viewbox: '0 0 40 40',
    baseClassName: ''
  },
  'arrow-left': {
    viewbox: '0 0 30 30',
    baseClassName: 'fill-secondary-50'
  },
  clipboard: {
    viewbox: '0 0 30 38',
    baseClassName: 'fill-primary-500'
  },
  'favorite-star': {
    viewbox: '0 0 13 12',
    baseClassName: ''
  }
} satisfies IconConfigSchema;
