import { GameConfig } from '../../config/game-config';
import type { RSNConfigType } from './schema';
import type { RSN } from './type';

export function toRSN(rsn: RSNConfigType): RSN {
  const platformLogos = rsn[GameConfig.get.basePlatform];

  return {
    displayOrder: rsn.displayOrder,
    id: rsn.id,
    name: rsn.name,
    logo: rsn.logo,
    logoLarge: platformLogos.logoLarge,
    defaultHeroBg: platformLogos.defaultHeroBg,
    csaiEnabled: rsn.csaiEnabled,
    csaiAdProvider: rsn.csaiAdProvider,
    adobeResourceId: rsn.adobeResourceId
  };
}
