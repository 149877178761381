import { UAParser } from 'ua-parser-js';

// Determines if device is an Apple device.
export function isApple() {
  const userAgent = UAParser();
  const os = userAgent.os.name;
  return os === 'iOS' || os === 'Mac OS';
}

export function isSafari() {
  const userAgent = UAParser();
  switch (userAgent.browser.name) {
    case 'Mobile Safari':
    case 'Safari':
      return true;
    default:
      return false;
  }
}
