import { UserNotAuthenticatedError } from '../../lib/error';
import { fetchFlatToken, getEntitlements } from '../../services/api/evergent';
import { getTokens } from '../auth';
import { type User } from '../user';
import { getEvergentFlatToken, setEvergentFlatToken } from './token-storage';

export async function getUserEntitlements() {
  const { entitlements } = await getEntitlements();

  return entitlements;
}

export async function getUserEntitlementsZone() {
  const { dmaID } = await getEntitlements();
  return dmaID;
}

export function isEntitled(user: User, contentEntitlements: Set<string>): boolean {
  if (contentEntitlements.has('urn:package:free')) {
    return true;
  }

  const userEntitlements = new Set<string>(
    [...user.entitlements].filter((ent: string) => {
      // Only keep TVE entitlements if user.mvpdId is truthy
      if (ent.startsWith('urn:package:tve')) {
        return Boolean(user.mvpdId);
      }
      // Otherwise always keep it
      return true;
    })
  );

  // Check if any of the contentEntitlements exist in userEntitlements
  let entitled = false;
  for (const contentEntitlement of contentEntitlements) {
    if (userEntitlements.has(contentEntitlement)) {
      entitled = true;
      break;
    }
  }

  return entitled;
}

export async function getFlatToken() {
  let flatToken = getEvergentFlatToken();
  if (flatToken && flatToken?.expire && flatToken?.expire > Date.now()) {
    return flatToken;
  }
  const tokens = getTokens();
  if (!tokens) throw new UserNotAuthenticatedError('User tokens are not available');
  flatToken = await fetchFlatToken(tokens.accessToken);
  saveFlatToken(flatToken.token, flatToken.expire);
  return flatToken;
}

export function saveFlatToken(ovatToken: string, ovatExpireTimestamp: number) {
  setEvergentFlatToken(ovatToken, ovatExpireTimestamp);
}

export function getContentEntitlementTypes(contentEntitlements: Set<string>) {
  const types = new Set<'DTC' | 'TVE' | 'FREE'>();
  const typeChecker = (type: string) => [...contentEntitlements].some((ent) => ent.includes(type));
  if (typeChecker('dtc')) types.add('DTC');
  if (typeChecker('tve')) types.add('TVE');
  if (typeChecker('free')) types.add('FREE');
  return types;
}
