import { emit, getSearchedAccountFromStorage, isFeatureEnabled } from '@game/core';
import { createFileRoute, redirect } from '@tanstack/react-router';
import { getPagePath } from '../../../../lib/routing';

export const Route = createFileRoute('/_public/_unauthenticated/create-profile')({
  beforeLoad: async () => {
    const data = getSearchedAccountFromStorage();

    if (!data?.email) throw redirect({ to: getPagePath('launch') });

    return {
      email: data.email
    };
  },
  loader: async () => {
    emit('Onboard');
    const referrals = await isFeatureEnabled('referrals');
    const newsletter = await isFeatureEnabled('newsletter');
    return {
      features: {
        referrals,
        newsletter
      }
    };
  }
});
