const COUCH_RIGHTS_END_DATE_KEY = 'couchRightsEndDate';

export function get(): number | null {
  const timestamp = localStorage.getItem(COUCH_RIGHTS_END_DATE_KEY);
  try {
    return Number(timestamp);
  } catch {
    localStorage.removeItem(COUCH_RIGHTS_END_DATE_KEY);
    return null;
  }
}

export const set = (timestamp: number) => {
  localStorage.setItem(COUCH_RIGHTS_END_DATE_KEY, timestamp.toString());
};

export const reset = () => {
  localStorage.removeItem(COUCH_RIGHTS_END_DATE_KEY);
};
