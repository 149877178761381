import { z } from 'zod';

export const contactSupportLocalesSchema = z
  .object({
    contactSupportScreen: z.object({
      contactSupport: z.string(),
      question: z.string(),
      hearYou: z.string(),
      liveChat: z.string(),
      sendEmail: z.string(),
      callUs: z.string()
    })
  })
  .transform((values) => values.contactSupportScreen);

export type ContactSupportLocale = z.infer<typeof contactSupportLocalesSchema>;
