import { isUserLoggedIn } from '@game/core';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import { getPagePath } from '../../lib/routing';

export const Route = createFileRoute('/_public/_unauthenticated')({
  beforeLoad: () => {
    if (isUserLoggedIn()) {
      throw redirect({ to: getPagePath('watch') });
    }
  },
  component: Outlet
});
