import * as evergent from '../../services/api/evergent';
import { getUserLocation, type LocationData } from '../check-location';
import { getContentEntitlementTypes } from '../entitlements/lib';
import { getUserData, isDTCUser, isVIPUser, type User } from '../user';
import * as couchRightsCache from './storage';
import { getDeviceId } from '../device/lib';
import { isFeatureEnabled } from '../features-manager';
import type { Content } from '../content';
import { CouchRightsError } from './error';
import { z } from 'zod';
import { getConfigFromRemote } from '../config-manager';
import { canSubscribe } from '../subscription-plans';

type UserLocation = Pick<LocationData, 'zip' | 'zone'>;

const schema = z
  .object({ couchRightsSeconds: z.number() })
  .transform(({ couchRightsSeconds }) => ({ couchRightsDuration: couchRightsSeconds * 1000 }));

/**
 * Check the couch right
 *
 * @throws {@link CouchRightsError}
 * Throw with type 'expired' if the couch right limit has been reached
 * Throw with type 'outOfMarket' if couch right feature is disabled and user is not entitled to play the video
 *
 */
export async function checkCouchRights() {
  const user = await getUserData();
  if (!isRegularDTCUser(user)) {
    return;
  }

  const { couchRightsDuration } = getConfigFromRemote(schema);
  const [userLocation, deviceId, isCouchRightsEnabled] = await Promise.all([
    getUserLocation(),
    getDeviceId(),
    isFeatureEnabled('couchRights')
  ]);

  if (await canSubscribe()) {
    couchRightsCache.reset();
    evergent.addOrUpdateCouchRights(deviceId, user.entitlementsZone, userLocation.zip).catch(console.error);
    return;
  }

  console.info(new Date(), 'isCouchRightsEnabled', couchRightsDuration);

  if (!isCouchRightsEnabled) {
    evergent.addOrUpdateCouchRights(deviceId, userLocation.zone, userLocation.zip).catch(console.error);
    throw new CouchRightsError('outOfMarket');
  }

  const endDate = await getCouchRightsEndDate({
    deviceId,
    location: userLocation,
    defaultCouchRightDuration: couchRightsDuration
  });

  if (!endDate || endDate <= Date.now()) {
    throw new CouchRightsError('expired');
  }
}

async function getCouchRightsEndDate({
  deviceId,
  location,
  defaultCouchRightDuration
}: {
  deviceId: string;
  location: UserLocation;
  defaultCouchRightDuration: number;
}): Promise<number | null> {
  const cachedEndDate = couchRightsCache.get();

  if (cachedEndDate && cachedEndDate > Date.now()) {
    return cachedEndDate;
  }

  let endDate: number | null = null;
  try {
    const { GetCouchRightsResponseMessage } = await evergent.getCouchRights(deviceId);
    endDate = GetCouchRightsResponseMessage.endDate ?? null;
  } catch {
    if (!cachedEndDate) {
      endDate = Date.now() + defaultCouchRightDuration;
    }
  }

  endDate && couchRightsCache.set(endDate);

  if (!cachedEndDate) {
    evergent.addOrUpdateCouchRights(deviceId, location.zone, location.zip).catch(console.error);
  }

  return endDate;
}

/**
 * Determines whether couch rights validation should be applied
 * for a given content based on its entitlements
 * @param content Content to check
 */
export async function shouldCheckCouchRights(content: Content) {
  const contEntTypes = getContentEntitlementTypes(content.entitlements);
  const hasDTC = contEntTypes.has('DTC');
  const hasTVE = contEntTypes.has('TVE');
  const hasFREE = contEntTypes.has('FREE');
  if ((hasDTC && !hasTVE && !hasFREE) || (hasTVE && !hasDTC && !hasFREE)) {
    return true;
  } else if ((hasTVE || hasFREE) && !hasDTC) {
    return false;
  } else if (hasDTC && hasTVE && !hasFREE) {
    const user = await getUserData();
    const contentTVEEnts = [...content.entitlements].filter((ent) => ent.includes('tve'));
    const userTVEEnts = [...user.entitlements].filter((ent) => ent.includes('tve'));
    return !userTVEEnts.some((userTVEEnt) => contentTVEEnts.includes(userTVEEnt));
  }
  return true;
}

function isRegularDTCUser(user: User) {
  return isDTCUser(user) && !isVIPUser(user);
}
