import { z } from 'zod';

export const productsLocalesSchema = z
  .object({
    upsell: z.object({
      'current-plan': z.string(),
      welcome: z.string(),
      'want-more': z.string(),
      'upsell-description-line-one': z.string(),
      'upsell-description-line-two': z.string(),
      'upsell-description-line-three': z.string(),
      'want-upgrade': z.string(),
      'purchase-on-platform': z.string(),
      'continue-with-current-plan': z.string(),
      'upgrade-now': z.string(),
      'tve-message': z.string(),
      'upsell-ineligible': z.string()
    }),
    'purchase-plan': z.object({
      step2of3: z.string(),
      title: z.string(),
      description: z.string(),
      annual: z.string(),
      monthly: z.string(),
      'annual-subscription': z.string(),
      'monthly-subscription': z.string(),
      'subscribe-now': z.string(),
      'connect-with-tv-provider': z.string(),
      'continue-without-subscription': z.string(),
      'plan-selected-prompt': z.string(),
      'name-on-card': z.string(),
      'credit-card-number': z.string(),
      'zip-code': z.string(),
      expiry: z.string(),
      'cvv-code': z.string(),
      'discount-code': z.string(),
      'apply-button': z.string(),
      'amount-due-today-prompt': z.string(),
      'review-checkbox-description': z.string(),
      'cost-summary': z.string(),
      'paypal-review-description': z.string(),
      'paypal-and': z.string(),
      'paypal-disclaimer': z.string(),
      'terms-of-use': z.string(),
      'privacy-policy': z.string(),
      'protected-by-recaptcha': z.string(),
      and: z.string(),
      apply: z.string(),
      'purchase-now': z.string(),
      'tax-yearly': z.string(),
      'tax-monthly': z.string(),
      'tax-game': z.string(),
      'tabs-aria-label': z.string(),
      'modal-success-title': z.string(),
      'modal-success-description': z.string(),
      'modal-error-title': z.string(),
      paypal: z.string(),
      'apple-pay': z.string(),
      'credit-card': z.string(),
      'game-pass': z.string(),
      'buy-now': z.string(),
      'subscribe-to-unlimited': z.string(),
      'pay-full-amount': z.string(),
      'pay-in-6-installment': z.string(),
      'pay-in-3-installment': z.string(),
      'apple-pay-payment-description': z.string(),
      'apple-pay-billing-agreement': z.string(),
      'apple-pay-button': z.string(),
      purchase: z.string(),
      'pay-in-installments': z.string(),
      cancel: z.string(),
      'promo-code-not-applied': z.string(),
      'promo-code-missing': z.string(),
      'discount-code-display': z.string()
    }),
    generic: z.object({
      ok: z.string()
    })
  })
  .transform((values) => {
    const {
      step2of3,
      title,
      description,
      'plan-selected-prompt': planSelected,
      annual,
      monthly,
      'annual-subscription': annualSubscription,
      'monthly-subscription': monthlySubscription,
      'name-on-card': nameOnCard,
      'credit-card-number': creditCardNumber,
      'zip-code': zipCode,
      expiry,
      'cvv-code': cvvCode,
      'discount-code': discountCode,
      'apply-button': applyButton,
      'amount-due-today-prompt': amountDueToday,
      'cost-summary': costSummary,
      'paypal-review-description': paypalReviewDescription,
      'paypal-and': paypalAnd,
      'paypal-disclaimer': paypalDisclaimer,
      'review-checkbox-description': reviewDescription,
      'subscribe-now': subscribeNow,
      'connect-with-tv-provider': connectWithTVProvider,
      'terms-of-use': termsOfUse,
      'privacy-policy': privacyPolicy,
      'protected-by-recaptcha': protectedByRecaptcha,
      apply,
      and,
      'purchase-now': purchaseNow,
      'continue-without-subscription': continueWithoutSubscription,
      'tax-yearly': taxYearly,
      'tax-monthly': taxMonthly,
      'tax-game': taxGame,
      'tabs-aria-label': tabsAriaLabel,
      'modal-success-title': modalSuccessTitle,
      'modal-success-description': modalSuccessDescription,
      'modal-error-title': modalErrorTitle,
      paypal,
      'apple-pay': applePay,
      'credit-card': creditCard,
      'game-pass': gamePass,
      'buy-now': buyNow,
      'subscribe-to-unlimited': subscribeToUnlimited,
      'pay-full-amount': payFullAmount,
      'pay-in-6-installment': pay6Installments,
      'pay-in-3-installment': pay3Installments,
      'apple-pay-payment-description': applePayPaymentDescription,
      'apple-pay-billing-agreement': applePayBillingAgreement,
      'apple-pay-button': applePayButton,
      purchase,
      'pay-in-installments': payInInstallments,
      cancel,
      'promo-code-not-applied': promoCodeNotApplied,
      'promo-code-missing': promoCodeMissing,
      'discount-code-display': discountCodeDisplay
    } = values['purchase-plan'];
    const {
      'current-plan': currentPlan,
      'want-more': wantMore,
      'upsell-description-line-one': upsellDescription1,
      'upsell-description-line-two': upsellDescription2,
      'upsell-description-line-three': upsellDescription3,
      'want-upgrade': wantUpgrade,
      'purchase-on-platform': purchaseOnPlatform,
      'continue-with-current-plan': continueWithCurrentPlan,
      'upgrade-now': upgradeNow,
      'tve-message': tveMessage,
      'upsell-ineligible': upsellIneligible,
      welcome
    } = values.upsell;
    const { ok } = values['generic'];

    return {
      step2of3,
      title,
      description,
      planSelected,
      annual,
      monthly,
      annualSubscription,
      monthlySubscription,
      nameOnCard,
      creditCardNumber,
      zipCode,
      expiry,
      cvvCode,
      discountCode,
      applyButton,
      apply,
      and,
      ok,
      amountDueToday,
      costSummary,
      paypalReviewDescription,
      paypalAnd,
      paypalDisclaimer,
      reviewDescription,
      privacyPolicy,
      purchaseNow,
      subscribeNow,
      connectWithTVProvider,
      termsOfUse,
      protectedByRecaptcha,
      continueWithoutSubscription,
      taxYearly,
      taxMonthly,
      taxGame,
      tabsAriaLabel,
      modalSuccessTitle,
      modalSuccessDescription,
      modalErrorTitle,
      paypal,
      applePay,
      creditCard,
      gamePass,
      buyNow,
      subscribeToUnlimited,
      payFullAmount,
      pay6Installments,
      pay3Installments,
      applePayPaymentDescription,
      applePayBillingAgreement,
      applePayButton,
      currentPlan,
      wantMore,
      upsellDescription1,
      upsellDescription2,
      upsellDescription3,
      wantUpgrade,
      purchaseOnPlatform,
      continueWithCurrentPlan,
      upgradeNow,
      tveMessage,
      welcome,
      purchase,
      payInInstallments,
      cancel,
      upsellIneligible,
      promoCodeNotApplied,
      promoCodeMissing,
      discountCodeDisplay
    };
  });

export const productsLegalLocalesSchema = z
  .object({
    'purchase-plan': z.object({
      disclaimer: z.string()
    })
  })
  .transform((values) => {
    const { disclaimer } = values['purchase-plan'];
    return { disclaimer };
  });

export type ProductsLocales = z.infer<typeof productsLocalesSchema>;
