import { generateUUID, getSearchedAccountFromStorage } from '@game/core';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import { sha256 } from 'js-sha256';

const DEVICE_ID_KEY = 'DEVICE_ID';

const DEVICE_EMAIL_HASH_KEY = 'DEVICE_HSH';

export async function getDeviceId() {
  return generateDeviceId();
}

async function generateDeviceId() {
  const device = await getDeviceFingerprint();

  const complement = getDeviceEmailHash();

  return `${device}${complement}`.slice(0, 100);
}

export function setDeviceEmailHash(email?: string) {
  // TODO: remove UUID for better fallback
  const hash = sha256(email || getSearchedAccountFromStorage()?.email || generateUUID());

  localStorage.setItem(DEVICE_EMAIL_HASH_KEY, hash);
}

function getDeviceEmailHash() {
  const complement = localStorage.getItem(DEVICE_EMAIL_HASH_KEY);

  if (complement) return complement;

  setDeviceEmailHash();

  return getDeviceEmailHash();
}

async function setDeviceFingerprint() {
  const hash = (await getFingerprint()) || sha256(generateUUID());

  localStorage.setItem(DEVICE_ID_KEY, hash);
}

async function getDeviceFingerprint() {
  const fingerprint = localStorage.getItem(DEVICE_ID_KEY);

  if (fingerprint) return fingerprint;

  await setDeviceFingerprint();

  return getDeviceFingerprint();
}
