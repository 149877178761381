import { queryOptions } from '@tanstack/react-query';
import { GetCheckLocationQuery } from '../check-location';
import { GetUserQuery } from '../user';
import { canSubscribe, getActiveSubscriptions, getProducts, getTVODPurchaseToken } from './services';
import type { GetPurchaseTokenQueryParams } from './types';

const ACTIVE_SUBSCRIPTIONS_STALE_TIME = 5 * 60 * 1000;
const CAN_SUBSCRIBE_STALE_TIME = 5 * 60 * 1000;

export const GetActiveSubscriptionsQuery = queryOptions({
  queryKey: [...GetUserQuery.queryKey, 'subscriptions'],
  queryFn: getActiveSubscriptions,
  staleTime: ACTIVE_SUBSCRIPTIONS_STALE_TIME
});

export function GetPurchaseTokenQuery(params: GetPurchaseTokenQueryParams) {
  const { content, plan } = params;
  const contentId = content?.id;
  return queryOptions({
    queryKey: [...GetUserQuery.queryKey, 'purchaseToken', contentId],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- handled by enabled condition
    queryFn: () => getTVODPurchaseToken({ contentId: contentId! }),
    enabled: !!(
      plan.isGamePass &&
      contentId &&
      content &&
      content.gamePassAssetId &&
      (content.status === 'PRE' || content.status === 'LIVE')
    )
  });
}

export const GetCanSubscribeQuery = queryOptions({
  queryKey: [...GetCheckLocationQuery().queryKey, 'canSubscribe'],
  queryFn: canSubscribe,
  staleTime: CAN_SUBSCRIBE_STALE_TIME
});

export function GetProductsQuery(dmaID: string) {
  return queryOptions({
    queryKey: ['products', dmaID],
    queryFn: async () => getProducts({ dmaID })
  });
}
