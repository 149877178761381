import { useQuery, type MutationFunction, type QueryFunctionContext, type QueryOptions } from '@tanstack/react-query';
import { queryClient } from '../../lib/query';
import { isValueInArray } from '../../lib/utils';
import { numberToCurrency } from '../../lib/utils/numberFormat';
import {
  changeService as changeEvergentService,
  getPaymentMethods as evergentPaymentMethods,
  updatePaymentMethod as evergentUpdatePaymentMethods,
  getPayments as getEvergentPayments,
  removeSubscription as removeEvergentSubscription,
  resumeSubscription as resumeEvergentSubscription,
  type ActiveSubscription
} from '../../services/api/evergent';
import { getProrate } from '../../services/api/evergent/services';
import type {
  RemoveSubscriptionMutationRequest,
  RemoveSubscriptionMutationResponse,
  RemoveSubscriptionRequest,
  UpdateCardParams
} from '../../services/api/evergent/types';
import type { QuickplayCatalogType } from '../../services/api/quickplay';
import { invalidateUserData } from '../user';
import { PLAN_TYPES } from './constants';
import { GetActiveSubscriptionsQuery, GetProductsQuery } from './query';
import { addProductSubscription, getActiveSubscriptions, getGamePassDetails, processTVODOrder } from './services';
import type {
  AddProductInputData,
  AddTVODOrderInputData,
  ApplyDiscountCodeInputData,
  ApplyDiscountResponse,
  Location,
  PlanTypes,
  Subscription,
  SubscriptionPlan,
  TVODOrder,
  UseCurrentSubscriptionPlanParams,
  UseSubscriptionPlansParams
} from './types';
import { getSubscriptionPlansLogos, isSubscriptionGamePass } from './utils';

export { getPurchaseHistory } from './services';

function sortPlans(plans: SubscriptionPlan[]) {
  return plans.sort((plan1, plan2) => {
    if ((plan1.isBundle && plan2.isBundle) || (!plan1.isBundle && !plan2.isBundle)) {
      return plan1.displayOrder - plan2.displayOrder;
    }
    return plan1.isBundle ? 1 : -1;
  });
}

async function getSubscriptionPlans(params: QueryFunctionContext) {
  const [, dmaID] = params.queryKey;

  const plansByType = new Map<PlanTypes, SubscriptionPlan[]>();

  if (typeof dmaID !== 'string') return plansByType; // this is just for TS to not complain about the type of dmaID

  const products = await getSubscriptionProducts(dmaID);

  if (!products || !products.length) return plansByType;

  const { SKU_ID_NETWORKS_LOGOS, SKU_ID_TEAMS_LOGOS, SKU_REGEX_TO_ID } = getSubscriptionPlansLogos(dmaID);

  for (const product of products) {
    const { displayOrder, productName, retailPrice, displayName, productID, sku, ovpSKU, promotions } = product;

    const productNameLowerCase = productName.toLowerCase();

    let network, type;
    const parts = productNameLowerCase.split(/[._]/);
    switch (parts.length) {
      case 3:
        network = parts[1];
        type = parts[2];
        break;
      case 4:
        network = parts[1];
        type = parts[3];
        break;
      default:
        console.warn(`invalid product name encountered '${productNameLowerCase}'`);
    }

    let skuId = SKU_REGEX_TO_ID.get(network as string);

    const isGamePass = isSubscriptionGamePass(product);

    if (isGamePass) {
      type = PLAN_TYPES[2];
      skuId = -1; // TODO: Change this for any matcher for any logo game pass has
    }

    if (!skuId || !isValueInArray(PLAN_TYPES, type)) continue;

    // TODO: filter by non expired
    const promotionalPrice = promotions?.length ? promotions[0].promotionalPrice : 0;
    const promotionalPriceExpiry = promotions?.length ? new Date(promotions[0].promotionExpiry) : undefined;

    const planToPush = {
      type,
      productID,
      sku,
      ovpSKU,
      displayName,
      displayOrder,
      price: retailPrice,
      displayPrice: numberToCurrency(retailPrice),
      promotionalPrice: promotionalPrice,
      displayPromotionalPrice: promotionalPrice ? numberToCurrency(promotionalPrice) : undefined,
      promotionalPriceExpiry,
      teamLogos: SKU_ID_TEAMS_LOGOS.get(skuId) ?? [],
      rsnsLogo: SKU_ID_NETWORKS_LOGOS.get(skuId) ?? [],
      isBundle: network === 'bundle',
      isGamePass
    };

    plansByType.has(type) ? plansByType.get(type)?.push(planToPush) : plansByType.set(type, [planToPush]);
  }

  plansByType.forEach((plans, type) => {
    plansByType.set(type, sortPlans(plans));
  });

  return plansByType;
}

export const prefetchSubscriptionPlansOptions = (dmaID: string) =>
  ({
    queryKey: ['subscription-plans', dmaID],
    queryFn: getSubscriptionPlans
  }) satisfies QueryOptions;

export const useSubscriptionPlans = (params: UseSubscriptionPlansParams) => {
  const { planType, zone } = params;

  const { data } = useQuery({
    ...prefetchSubscriptionPlansOptions(zone),
    staleTime: Infinity
  });

  return data?.get(planType) ?? [];
};

export const useCurrentSubscriptionPlan = (params: UseCurrentSubscriptionPlanParams) => {
  const { subscription, zone } = params;
  const planType = (subscription.ovpSKU?.split(':').pop() || 'annual') as PlanTypes;
  const plans = useSubscriptionPlans({ planType, zone });
  return plans.find((plan) => plan.sku === subscription.id);
};

export const getSubscriptionProducts = async (dmaID: string) => {
  return queryClient.fetchQuery(GetProductsQuery(dmaID));
};

export async function getProductBySku(zone: string, sku?: string) {
  if (!sku) return null;

  const products = await getSubscriptionProducts(zone);

  return products.find((product) => product.sku === sku) ?? null;
}

type ChangeServiceProps = {
  currentPlan: SubscriptionPlan | ActiveSubscription; // TODO: refactor ChangeSubscription to not use SubscriptionPlan.
  newPlan: SubscriptionPlan;
};

export const changeService = async (props: ChangeServiceProps) => {
  const { currentPlan, newPlan } = props;
  await changeEvergentService({ oldServiceID: currentPlan.sku, newServiceID: newPlan.sku });
  await invalidateUserData();
};

const invalidateActiveSubscriptionsQuery = async () => {
  await queryClient.invalidateQueries({ queryKey: GetActiveSubscriptionsQuery.queryKey });
};

export const removeSubscriptionMutationFunction: MutationFunction<
  RemoveSubscriptionMutationResponse,
  RemoveSubscriptionMutationRequest
> = async (props) => {
  const { serviceID, serviceType, reasonCode } = props;
  await removeSubscription({
    serviceID,
    serviceType,
    reasonCode
  });

  await invalidateActiveSubscriptionsQuery();

  return {
    serviceID,
    reasonCode
  };
};

export const removeSubscription = async (props: RemoveSubscriptionRequest) => {
  return removeEvergentSubscription(props);
};

export const resumeSubscription = async (props: ActiveSubscription) => {
  const { id, serviceType } = props;

  await resumeEvergentSubscription({
    serviceID: id,
    serviceType
  });

  await invalidateActiveSubscriptionsQuery();
};

export const addProductMutationFunction: MutationFunction<void, AddProductInputData> = async (args) => {
  const { subscription, location } = args;
  await addProduct(subscription, location);
};

export const addProduct = async (sub: Subscription, location: Location) => {
  const response = await addProductSubscription(sub, location);
  await invalidateUserData();
  return response;
};

export const getSubscribedProducts = async () => {
  return getActiveSubscriptions();
};

export const getPayments = () => {
  return getEvergentPayments();
};

export const getPaymentMethods = () => {
  return evergentPaymentMethods();
};

export const updatePaymentMethods = (newCardDetails: UpdateCardParams) => {
  return evergentUpdatePaymentMethods(newCardDetails);
};

export const getGamePassInfo = async (gamePassID: string, catalogType: QuickplayCatalogType) => {
  return getGamePassDetails(gamePassID, catalogType);
};

export const addTVODOrderMutationFunction: MutationFunction<void, AddTVODOrderInputData> = async (args) => {
  const { order, location } = args;
  await addTVODOrder(order, location);
};

export const addTVODOrder = async (order: TVODOrder, location: Location) => {
  const response = await processTVODOrder(order, location);
  await invalidateUserData();
  return response;
};

export const applyDiscountCodeMutationFunction: MutationFunction<
  ApplyDiscountResponse,
  ApplyDiscountCodeInputData
> = async (args) => {
  const { location, serviceId, couponCode } = args;
  return await applyDiscountCode(location.dmaID, serviceId, couponCode, location.zip);
};

export const applyDiscountCode = async (dmaID: string, serviceId: string, couponCode: string, zipCode: string) => {
  return await getProrate(dmaID, serviceId, couponCode, zipCode);
};
