import { isFeatureEnabled } from '@game/core';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { AppSideBar } from '../../components';

// TODO: For now I'm keeping this function, because we don't know if this logic will be reverted or not
// function pageHasSidebar(path: string) {
//   return !/account\/.*/.test(path);
// }

async function getWatchFeatures() {
  const odds = (await isFeatureEnabled('odds')) ?? false;
  const liveScores = (await isFeatureEnabled('liveScores')) ?? false;
  const heroCarouselAutoRotation = (await isFeatureEnabled('heroCarouselAutoRotation')) ?? false;
  const heroCarouselBackgroundVideo = (await isFeatureEnabled('heroAutoPlay')) ?? false;

  return {
    odds,
    liveScores,
    heroCarouselAutoRotation,
    heroCarouselBackgroundVideo
  };
}

export type WatchFeatures = Awaited<ReturnType<typeof getWatchFeatures>>;

export const Route = createFileRoute('/_private/_home')({
  beforeLoad: async () => {
    const features = await getWatchFeatures();

    return {
      features,
      footer: true
    };
  },
  component: RouteComponent
});

function RouteComponent() {
  const { features } = Route.useRouteContext();

  return (
    <div className="bg-secondary-900 flex-1">
      <AppSideBar features={features} />
      <main className="md:pl-[5.8125rem]">
        <Outlet />
      </main>
    </div>
  );
}
