import { z } from 'zod';

export const easeLiveConfigSchema = z.object({
  easeLive: z.object({
    accountId: z.string(),
    overlayUrl: z.string(),
    easeLiveUrl: z.string(),
    environment: z.string(),
    easeLiveDefaultStateOn: z.boolean()
  })
});

export type EaseLiveRemoteConfig = z.infer<typeof easeLiveConfigSchema>;
