import { z } from 'zod';

export const manageSupportLocalesSchema = z
  .object({
    manageSupport: z.object({
      header: z.string(),
      description: z.string(),
      primaryButton: z.string()
    })
  })
  .transform((values) => values.manageSupport);

export type ManageSupportLocale = z.infer<typeof manageSupportLocalesSchema>;
