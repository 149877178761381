import { queryOptions } from '@tanstack/react-query';
import { queryClient } from '../../lib/query';
import { GetCheckLocationQuery } from '../check-location';
import { GetUserQuery } from '../user';
import { getTeams, getUserFavouriteTeamsMap } from './lib';
import type { Team } from './type';

export const GetTeamsQuery = queryOptions({
  queryKey: [...GetUserQuery.queryKey, ...GetCheckLocationQuery().queryKey, 'teams'],
  queryFn: getTeams
});

function compareUserFavorites(this: any, a: Team, b: Team) {
  const isAFav = this.has(a.id) ? 1 : 0;
  const isBFav = this.has(b.id) ? 1 : 0;

  return -(isAFav - isBFav);
}

export const GetTeamsFavouriteSortedQuery = queryOptions({
  queryKey: [...GetUserQuery.queryKey, ...GetCheckLocationQuery().queryKey, 'teams-favourite-sorted'],
  queryFn: async () => {
    const teams = await queryClient.fetchQuery(GetTeamsQuery);
    const favouriteTeamMap = await queryClient.fetchQuery(getUserFavouriteTeamsMapQuery('id'));
    return teams.sort(compareUserFavorites.bind(favouriteTeamMap));
  }
});

export const getUserFavouriteTeamsMapQuery = (key?: 'id' | 'externalId') => {
  return queryOptions({
    queryKey: [...GetUserQuery.queryKey, ...GetCheckLocationQuery().queryKey, 'favourite-teams-map', key],
    queryFn: () => getUserFavouriteTeamsMap(key)
  });
};
